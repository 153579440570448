@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.testClass {
  background-color: rgb(91, 89, 89);
}

/* Keyframes for the animation */
@keyframes slideInBoing {
  0% {
    transform: translateX(-100%) scale(0.8);
  }
  50% {
    transform: translateX(20px) scale(1.2);
  }
  70% {
    /* Slight left to give the "boing" feel */
  }
  100% {
    transform: translateX(0) scale(1);
  }
}

/* Apply animation */
.animate-slideInBoing {
  animation: slideInBoing 3s ease-in-out forwards;
}

/* Base styles that are always applied */
.contact-form-3d-container {
  position: relative;
  transition: all 0.3s ease;
}

.contact-form-3d-container form {
  background-color: black;
  border-radius: 12px;
  padding: 1.5rem;
}

.contact-form-3d-container form input,
.contact-form-3d-container form textarea {
  border-radius: 8px;
}

.contact-form-3d-container form button {
  border-radius: 8px;
}

/* 3D styles that will be toggled */
.contact-form-3d {
  transform-style: preserve-3d;
  perspective: 1500px;
  transform: rotateX(10deg) rotateY(-15deg);
}

.contact-form-3d form {
  transform: rotateX(5deg) rotateY(5deg) skewY(10deg);
  transform-origin: center center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

/* Updated Swiper navigation buttons */
.swiper-button-next, 
.swiper-button-prev {
  position: absolute !important;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  color: white !important;
  border-radius: 20%;
  width: 40px !important;
  height: 40px !important;
  top: 60% !important;
  transform: translateY(-50%) !important;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 24px !important;
}

.swiper-button-prev {
  left: 10px !important;
}

.swiper-button-next {
  right: 10px !important;
}

/* Media Queries for Swiper buttons */
@media (max-width: 1600px) {
  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 20px !important;
  }
}

@media (max-width: 768px) {
  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 18px !important;
  }
}

@media (max-width: 480px) {
  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 16px !important;
  }
  
  .swiper-button-next,
  .swiper-button-prev {
    width: 30px !important;
    height: 30px !important;
  }
}

@keyframes spinY {
  0% {
    transform: rotateY(0deg);
  }
  95% {
    transform: rotateY(3600deg);
  }
  100% {
    transform: rotateY(3600deg);
  }
}

.contact-form-3d-container {
  animation: spinY 3s ease-in-out;
  transform-style: preserve-3d;
}

@font-face {
  font-family: Roboto;
  src: url('./assets/fonts/Roboto-Regular.ttf');
}

.text-shadow-light {
  text-shadow: 
    -1px -1px 0 #60A5FA,
    1px -1px 0 #60A5FA,
    -1px 1px 0 #60A5FA,
    1px 1px 0 #60A5FA,
    2px 2px 4px rgba(0,0,0,0.3);
}